import Link from 'next/link'
import Image from 'next/legacy/image'
import React from 'react'
import { useSession } from '@/utils/auth/user-context'

const Logo: React.FC = () => {
  const session = useSession()
  return (
    <Link href={!!session ? '/home' : '/'} legacyBehavior>
      <a className='relative inline-block'>
        <Image
          src='/static/logo-big.png'
          height={50}
          width={202.97}
          alt='Supermeme.ai logo'
        />
      </a>
    </Link>
  )
}

export default Logo
